import React from 'react';
import { CUSTOM_EVENT_EMPTY_CATEGORY, URL_IMAGES } from '../../components/constants';
import { Product } from '../../services';
import { useFetchProducts } from '../../hooks';
import { transformUrl } from '../../utils';
import ServiceFeeDisclaimer from '../../components/service-fee-disclaimer';
import { defaultFontFamily } from '../../styles';

type CategoryProductsPageProps = {
    categoryName: string;
    categoryId?: number;
    navigateToItem: (product: Product) => void;
};

export const CategoryProductsPage = ({ categoryId, categoryName, navigateToItem }: CategoryProductsPageProps) => {
    const { products, error: productError, isLoading: isProductLoading } = useFetchProducts(categoryId ?? null);

    const renderProductsList = () =>
        products?.map((product: Product) => (
            <li id={`product-${product.productId}`} key={product.productId} style={{ marginBottom: '16px' }}>
                <div style={{ display: 'flex', alignItems: 'flex-start', gap: '12px' }}>
                    <div style={{ flexBasis: '40%' }}>
                        <img
                            src={transformUrl(`${URL_IMAGES}${product.primaryImageUrl}`)}
                            alt={product.description}
                            style={{ width: '100%', height: 'auto' }}
                        />
                    </div>
                    <div style={{ flexBasis: '60%' }}>
                        <p style={{ fontWeight: 'bold', margin: '0px', marginBottom: '4px' }}>{!!product.name ? product.name : '-'}</p>
                        <p style={{ fontWeight: 'bold', fontSize: '14px', margin: '0px', marginBottom: '4px' }}>
                            ${product.price.toFixed(2)}
                        </p>
                        <p
                            style={{
                                fontSize: '12px',
                                margin: '0px',
                                marginBottom: '4px',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                maxWidth: '200px'
                            }}
                            title={product?.description ?? 'There is no description for this product'}
                            aria-label={product?.description ?? 'There is no description for this product'}
                        >
                            {product?.description ?? '-'}
                        </p>

                        <button
                            onClick={() => {
                                navigateToItem(product);
                            }}
                            style={{
                                background: 'black',
                                color: 'white',
                                textTransform: 'uppercase',
                                borderRadius: '20px 0 20px 0',
                                padding: '8px 24px',
                                marginTop: '16px'
                            }}
                        >
                            See details
                        </button>
                    </div>
                </div>
            </li>
        ));

    if (isProductLoading) {
        return <div>loading...</div>;
    }

    if (productError === CUSTOM_EVENT_EMPTY_CATEGORY) {
        return (
            <div id={CUSTOM_EVENT_EMPTY_CATEGORY} style={{ margin: '24px 20px', textAlign: 'center' }}>
                <p>There are no products in this category</p>
            </div>
        );
    }

    if (productError) {
        return (
            <div style={{ margin: '24px 20px', textAlign: 'center' }}>
                <p>There was an error loading your products . Please try again.</p>
            </div>
        );
    }

    const generateProductsMessage = () => {
        if (Array.isArray(products) && products.length) {
            return `${products.length} ${products?.length > 1 ? 'products' : 'product'}`;
        }
        return '';
    };

    return (
        <>
            <section>
                <h2 style={{ ...defaultFontFamily, fontSize: '34px', margin: '8px 0px', lineHeight: '1.2' }}>{categoryName ?? '-'}</h2>

                <p style={{ ...defaultFontFamily, fontStyle: 'italic', fontSize: '14px', margin: '8px 0px 16px 0px' }}>
                    {generateProductsMessage()}
                </p>
                {products?.length ? (
                    <ul style={{ listStyleType: 'none', padding: 0 }}>{renderProductsList()}</ul>
                ) : (
                    <div style={{ ...defaultFontFamily }}>There are no products in this category.</div>
                )}
            </section>
            <ServiceFeeDisclaimer />
        </>
    );
};
