import React from 'react';
import { CategoriesPage, CartDetail, CartUpdate, CategoryProductsPage, ProductDetailPage } from '../index';

import { checkRequiredCookies, getCookie, isBrowser, hasErrorProperties } from '../../utils';
import { useVisibilityObserver } from '../../hooks/useVisibilityObserver';
import { useCategory, useNavigation, useOrderManagement } from '../../context';
import {
    COOKIE_CLOSE_WEBVIEW_TO_PREVIOUS_URL,
    PAGE_CART_DETAIL,
    PAGE_CART_UPDATE,
    PAGE_CATEGORY_LIST,
    PAGE_PRODUCT_DETAIL,
    PAGE_ITEM_LIST,
    requiredCookies,
    ERROR_MESSAGE_COOKIES,
    ERROR_MESSAGE_CATEGORY_ERROR,
    ERROR_MESSAGE_ORDER_ERROR
} from '../../components/constants';
import { Breadcrumbs, Header } from '../../components';
import { ErrorPage } from '../error-page';
import { defaultFontFamily } from '../../styles';

export const RouteSwitcher = () => {
    const { categories, filteredCategories, mainCategoryId, error: categoryError, isCategoryLoading, categoryTree } = useCategory();
    const { orderError, isOrderLoading } = useOrderManagement();
    const {
        currentPage,
        navigateToItem,
        navigateToCategoryProductsPage,
        navigateToCart,
        navigateToCategoriesPage,
        navigateToCategoryPage
    } = useNavigation();

    const closeWebViewToPreviousUrl = getCookie(COOKIE_CLOSE_WEBVIEW_TO_PREVIOUS_URL);

    useVisibilityObserver();

    const withTemplate = (content: JSX.Element): JSX.Element => (
        <div style={{ ...defaultFontFamily }}>
            <Header navigateToCart={navigateToCart} />
            <div style={{ margin: '24px 20px' }}>
                {shouldShowBreadcrums() ? (
                    <Breadcrumbs
                        categories={categoryTree!}
                        categoryId={mainCategoryId!}
                        navigateToCategoriesPage={navigateToCategoriesPage}
                    />
                ) : null}
                {content}
            </div>
        </div>
    );

    const shouldShowBreadcrums = () => currentPage === PAGE_CATEGORY_LIST || currentPage === PAGE_ITEM_LIST;

    const renderPage = () => {
        switch (currentPage) {
            case PAGE_CATEGORY_LIST:
                return (
                    <CategoriesPage
                        categories={filteredCategories}
                        categoryId={mainCategoryId ?? undefined}
                        navigateToCategoryProductsPage={navigateToCategoryProductsPage}
                        navigateToCategoryPage={navigateToCategoryPage}
                        cookieCategory={categories[0]}
                    />
                );
            case PAGE_ITEM_LIST:
                return (
                    <CategoryProductsPage
                        categoryName={categories.find(c => c.categoryId === mainCategoryId)?.name ?? '-'}
                        categoryId={mainCategoryId ?? undefined}
                        navigateToItem={navigateToItem}
                    />
                );
            case PAGE_PRODUCT_DETAIL:
                return (
                    <ProductDetailPage navigateToCategoryProductsPage={navigateToCategoryProductsPage} navigateToCart={navigateToCart} />
                );
            case PAGE_CART_DETAIL:
                return <CartDetail />;
            case PAGE_CART_UPDATE:
                return <CartUpdate />;
            default:
                return <p>{'Web View'}</p>;
        }
    };

    try {
        checkRequiredCookies(requiredCookies);
    } catch (error) {
        return <ErrorPage shouldDisplayHeader message={ERROR_MESSAGE_COOKIES} />;
    }

    if (isOrderLoading || isCategoryLoading) {
        return <div>Loading...</div>;
    }

    if (orderError && hasErrorProperties(orderError) && orderError.userAuthenticationError) {
        return (
            <ErrorPage message={orderError.userAuthenticationError}>
                <button
                    onClick={() => {
                        if (closeWebViewToPreviousUrl) {
                            window.location.href = closeWebViewToPreviousUrl;
                        }
                    }}
                    style={{
                        fontSize: '16px',
                        background: 'black',
                        color: 'white',
                        cursor: 'pointer',
                        padding: '10px 24px',
                        textTransform: 'uppercase',
                        borderRadius: '20px 0 20px 0',
                        marginTop: '16px',
                        textDecoration: 'none',
                        width: '100%',
                        textAlign: 'center'
                    }}
                >
                    Close Menu
                </button>
            </ErrorPage>
        );
    }

    if (orderError && hasErrorProperties(orderError) && (orderError.orderFetchError || orderError.orderUpdateError) && isBrowser()) {
        return (
            <ErrorPage shouldDisplayHeader message={ERROR_MESSAGE_ORDER_ERROR}>
                {orderError.orderFetchError && <p>{orderError.orderFetchError}</p>}
                {orderError.orderUpdateError && <p>{orderError.orderUpdateError}</p>}
            </ErrorPage>
        );
    }

    if (categoryError) {
        return <ErrorPage shouldDisplayHeader message={ERROR_MESSAGE_CATEGORY_ERROR} />;
    }

    return withTemplate(renderPage());
};
