import { useCallback, useEffect, useState } from 'react';
import { FetchError, Product, getProductsByCategoryId } from '../../services';
import { CUSTOM_EVENT_EMPTY_CATEGORY } from '../../components/constants';

/**
 *
 * @param categoryId
 * @returns
 */

export const useFetchProducts = (categoryId: number | null) => {
    const [products, setProducts] = useState<Product[] | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const fetchProducts = useCallback(async (id: number) => {
        setIsLoading(true);
        try {
            const fetchedProducts = await getProductsByCategoryId(id);
            setProducts(fetchedProducts || []);
            setError(null);
        } catch (error) {
            console.error('Failed to fetch products:', error);
            if (error instanceof FetchError) {
                if (error.status === 400) {
                    setError('Bad Request: The server could not understand the request to fetch products');
                } else if (error.status === 404) {
                    setError(CUSTOM_EVENT_EMPTY_CATEGORY);
                } else {
                    setError(error.message || 'Unknown error');
                }
            } else if (error instanceof Error) {
                setError(error.message);
            } else {
                setError('Unknown error');
            }
        } finally {
            setIsLoading(false);
        }
    }, []);

    useEffect(() => {
        if (categoryId !== null) {
            void fetchProducts(categoryId);
        }
    }, [categoryId, fetchProducts]);

    return { products, error, isLoading };
};
