import React, { createContext, useContext, useState, ReactNode } from 'react';
import {
    PAGE_CART_DETAIL,
    PAGE_CART_UPDATE,
    PAGE_CATEGORY_LIST,
    PAGE_CONFIRMATION_DIALOG,
    PAGE_PRODUCT_DETAIL,
    PAGE_ITEM_LIST
} from '../components/constants';
import { Product } from '../services';
import { Category } from '../services/category/get-category/types';
import { useCategory } from './category-context';
import { useOrderManagement } from './order-management-context';

interface NavigationContextProps {
    currentPage: string;
    navigateToCart: () => void;
    navigateToCartUpdate: () => void;
    navigateToCategoriesPage: () => void;
    navigateToConfirmation: (product: Product, quantity: number) => void;
    navigateToItem: (product: Product) => void;
    navigateToCategoryProductsPage: (categoryId?: number) => void;
    setCurrentPage: (page: string) => void;
    navigateToCategoryPage: (c: Category) => void;
}

const NavigationContext = createContext<NavigationContextProps | undefined>(undefined);

export const useNavigation = () => {
    const context = useContext(NavigationContext);
    if (!context) {
        throw new Error('useNavigation must be used within a NavigationProvider');
    }
    return context;
};

export const NavigationProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const { handleCategorySelection } = useCategory();
    const { setProduct, setProductQuantity } = useOrderManagement();
    const [currentPage, setCurrentPage] = useState<string>(PAGE_CATEGORY_LIST);

    const navigateToItem = (product: Product) => {
        setProduct(product);
        setCurrentPage(PAGE_PRODUCT_DETAIL);
    };

    const navigateToConfirmation = (product: Product, quantity: number) => {
        setProduct(product);
        setProductQuantity(quantity);
        setCurrentPage(PAGE_CONFIRMATION_DIALOG);
    };

    const navigateToCategoryProductsPage = (categoryId?: number) => {
        setCurrentPage(PAGE_ITEM_LIST);
        if (categoryId) {
            handleCategorySelection(categoryId);
        }
    };

    const navigateToCart = () => {
        setCurrentPage(PAGE_CART_DETAIL);
    };

    const navigateToCartUpdate = () => {
        setCurrentPage(PAGE_CART_UPDATE);
    };

    const navigateToCategoriesPage = () => {
        setCurrentPage(PAGE_CATEGORY_LIST);
    };

    const navigateToCategoryPage = (c: Category): void => {
        if (!c?.nestedCategories || c.nestedCategories.length === 0) {
            navigateToCategoryProductsPage(c.categoryId);
        } else {
            handleCategorySelection(c.categoryId);
        }
    };

    return (
        <NavigationContext.Provider
            value={{
                currentPage,
                setCurrentPage,
                navigateToItem,
                navigateToConfirmation,
                navigateToCategoryProductsPage,
                navigateToCart,
                navigateToCartUpdate,
                navigateToCategoriesPage,
                navigateToCategoryPage
            }}
        >
            {children}
        </NavigationContext.Provider>
    );
};
