import React from 'react';
import { InfoIcon } from '../../pages/cart-detail/info-icon';
import { disclaimerStyle } from '../../styles';

const ServiceFeeDisclaimer = ({}) => {
    return (
        <div style={{ display: 'flex', flexDirection: 'row', gap: '8px', marginTop: '36px' }}>
            <div>
                <InfoIcon />
            </div>
            <p style={disclaimerStyle}>
                Please note that all food and beverage items are subject to a 22% service charge plus applicable sales tax. This service
                charge is not a tip or gratuity and is not distributed to service employees. Additional payment for tips and gratuity for
                service, if any, is voluntary and at your discretion.
            </p>
        </div>
    );
};

export default ServiceFeeDisclaimer;
