import React, { useState } from 'react';
import { CartLine } from '../../services';
import { URL_IMAGES } from '../../components/constants';
import { numberToCurrentcy, transformUrl } from '../../utils';
import { useNavigation, useOrderManagement } from '../../context';
import { defaultFontFamily } from '../../styles';

export const CartUpdate = () => {
    const initList: { lineId: string; quantity?: number }[] = [];
    const { order, isOrderLoading, updateProducts, deleteProducts } = useOrderManagement();
    const { navigateToCart } = useNavigation();
    const [updatedOrder, setUpdatedOrder] = useState(order);
    const [modifiedList, setModifiedList] = useState(initList);
    const [removedList, setRemovedList] = useState(initList);

    const handleBackToCartDetail = () => {
        navigateToCart();
    };

    const handleSaveChanges = async () => {
        if (modifiedList.length) {
            await updateProducts(modifiedList);
        }
        if (removedList.length) {
            await deleteProducts(removedList);
        }
        navigateToCart();
    };

    const handleQuantityChange = (id: string, delta: number) => {
        if (updatedOrder) {
            setModifiedList([...modifiedList.filter(line => line.lineId !== id), { lineId: id, quantity: delta }]);
            setUpdatedOrder({
                ...updatedOrder,
                cart: {
                    ...updatedOrder.cart,
                    cartLines: updatedOrder?.cart.cartLines.map(line => (line.lineId === id ? { ...line, quantity: delta } : line))
                }
            });
        }
    };

    const handleRemove = async (id: string) => {
        setModifiedList(modifiedList.filter(line => line.lineId !== id));
        setRemovedList([...removedList, { lineId: id }]);
        if (updatedOrder) {
            setUpdatedOrder({
                ...updatedOrder,
                cart: {
                    ...updatedOrder.cart,
                    cartLines: updatedOrder?.cart.cartLines.filter(line => line.lineId !== id)
                }
            });
        }
    };

    return (
        <>
            <div
                style={{
                    borderBottom: '1px solid #EEEEEE',
                    display: 'flex',
                    flexDirection: 'column'
                }}
            >
                <button
                    style={{
                        ...defaultFontFamily,
                        display: 'flex',
                        border: 'none',
                        background: 'transparent',
                        textDecoration: 'underline',
                        cursor: 'pointer',
                        fontSize: '16px',
                        marginBottom: '12px'
                    }}
                    onClick={handleBackToCartDetail}
                >
                    &lt; Back
                </button>
                <h1 style={{ fontSize: '24px' }}>Edit your order</h1>
                <ul style={{ listStyleType: 'none', padding: 0 }}>
                    {updatedOrder?.cart.cartLines.map((item: CartLine) => (
                        <li
                            key={item.lineId}
                            style={{
                                ...defaultFontFamily,
                                marginBottom: '10px',
                                padding: '10px'
                            }}
                        >
                            <div style={{ display: 'flex', alignItems: 'flex-start', gap: '12px' }}>
                                <div style={{ flexBasis: '30%' }}>
                                    <img
                                        src={transformUrl(`${URL_IMAGES}${item?.product?.primaryImageUrl ?? ''}`)}
                                        alt={item?.product?.name ?? ''}
                                        style={{ width: 'auto', height: '86px' }}
                                    />
                                </div>
                                <div style={{ flexBasis: '70%', display: 'grid' }}>
                                    <span style={{ ...defaultFontFamily, fontWeight: 'bold', marginBottom: '8px' }}>
                                        {!!item.description ? item.product?.name : '-'}
                                    </span>
                                    <span style={{ ...defaultFontFamily, fontWeight: 600, marginBottom: '8px' }}>Quantity</span>
                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '12px' }}>
                                        <button
                                            style={{
                                                background: isOrderLoading || item.quantity === 1 ? '#bec0c2' : 'transparent',
                                                border: '1px solid gray',
                                                padding: '10px 12px',
                                                height: '34px'
                                            }}
                                            onClick={() => handleQuantityChange(item.lineId, item.quantity - 1)}
                                            disabled={isOrderLoading || item.quantity === 1}
                                        >
                                            -
                                        </button>
                                        <input
                                            type='number'
                                            value={item.quantity}
                                            readOnly
                                            style={{
                                                border: '1px solid gray',
                                                textAlign: 'center',
                                                width: '50px',
                                                height: '34px'
                                            }}
                                        />
                                        <button
                                            style={{
                                                background: 'transparent',
                                                border: '1px solid gray',
                                                padding: '10px 12px',
                                                height: '34px'
                                            }}
                                            onClick={() => handleQuantityChange(item.lineId, item.quantity + 1)}
                                        >
                                            +
                                        </button>
                                    </div>
                                    <span style={{ ...defaultFontFamily, fontWeight: 600, margin: '8px 0' }}>
                                        ${numberToCurrentcy(item.price)}
                                    </span>
                                </div>
                            </div>
                            <button
                                onClick={() => handleRemove(item.lineId)}
                                disabled={isOrderLoading}
                                style={{
                                    fontWeight: 'bold',
                                    border: 'none',
                                    background: 'transparent',
                                    color: '#AC1540',
                                    textDecoration: 'underline',
                                    cursor: 'pointer',
                                    marginTop: '10px',
                                    padding: 0
                                }}
                            >
                                Remove
                            </button>
                        </li>
                    ))}
                </ul>
            </div>
            <div>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: '16px' }}>
                    <button
                        onClick={handleSaveChanges}
                        style={{
                            ...defaultFontFamily,
                            background: 'black',
                            borderRadius: '20px 0 20px 0',
                            color: 'white',
                            marginTop: '24px',
                            padding: '12px',
                            textDecoration: 'none',
                            textTransform: 'uppercase',
                            width: '100%',
                            textAlign: 'center',
                            maxWidth: '348px',
                            cursor: 'pointer',
                            fontSize: '16px',
                            fontWeight: 600
                        }}
                    >
                        SAVE
                    </button>
                </div>
            </div>
        </>
    );
};
