import { useEffect } from 'react';
import { suspendOrder } from '../services';
import { getCookie, isBrowser } from '../utils';
import { COOKIE_ORDER_ID } from '../components/constants';

export const useVisibilityObserver = () => {
    const handleVisibilityChange = () => {
        if (isBrowser() && getCookie(COOKIE_ORDER_ID)) {
            // Defer the suspendOrder call to avoid blocking the main flow
            setTimeout(async () => {
                try {
                    await suspendOrder();
                } catch (error) {
                    // Log the error instead of setting orderError
                    console.error('Failed to suspend order:', error);
                }
            }, 0);
        }
    };

    useEffect(() => {
        document.addEventListener('visibilitychange', handleVisibilityChange);
        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, []);
};
