import React, { useEffect, useState } from 'react';
import { URL_IMAGES } from '../../components/constants';
import { Category } from '../../services/category/get-category/types';
import { transformUrl } from '../../utils';
import { useCategory, useNavigation } from '../../context';
import { defaultFontFamily } from '../../styles';

interface CategoriesPageProps {
    categories: Category[];
    categoryId?: number;
    navigateToCategoryPage: (category: Category) => void;
    navigateToCategoryProductsPage: (categoryId: number) => void;
    cookieCategory?: Category;
}

export const CategoriesPage = ({}: CategoriesPageProps) => {
    const { filteredCategories: categories, mainCategoryId: categoryId } = useCategory();
    const { navigateToCategoryProductsPage, navigateToCategoryPage } = useNavigation();
    const cookieCategory = categories[0];

    const [filteredCategories, setFilteredCategories] = useState<Category[]>([]);

    useEffect(() => {
        if (categories && categories.length > 0) {
            const filtered = categories.filter((c: Category) => c.parentCategory === categoryId);
            if (filtered.length > 0) {
                setFilteredCategories(filtered);
            } else {
                if (categoryId) {
                    navigateToCategoryProductsPage(categoryId);
                }
            }
        }
    }, [categories, categoryId]);

    const generateLink = (c: Category) => {
        return (
            <div style={{ marginTop: '20px', marginBottom: '28px' }} key={c.categoryId}>
                <button
                    style={{
                        ...defaultFontFamily,
                        color: 'black',
                        cursor: 'pointer',
                        fontSize: '24px',
                        fontWeight: 700,
                        lineHeight: 1.2,
                        textDecoration: 'underline',
                        border: 'none',
                        background: 'transparent',
                        textAlign: 'left'
                    }}
                    onClick={() => navigateToCategoryPage(c)}
                >
                    {c?.name}
                </button>
            </div>
        );
    };

    const generateTitle = () => {
        const currentCategoryIsRoot = cookieCategory && categoryId === cookieCategory?.categoryId;
        if (currentCategoryIsRoot) {
            return cookieCategory?.name;
        }

        const foundCategory = categories.find(c => c.categoryId === categoryId);
        if (foundCategory) {
            return foundCategory.name;
        } else {
            console.error('Error: cookieCategory is not part of fetched categories');
            return '';
        }
    };

    return (
        <div>
            <h2 style={{ fontSize: '34px', margin: '8px 0px', lineHeight: '1.2' }}>{generateTitle()}</h2>
            <p style={{ ...defaultFontFamily, margin: '0px', lineHeight: '1.2', marginBottom: '24px' }}>
                <span style={{ display: 'block' }}>You can add any of these options to your suite.</span>
                <span style={{ display: 'block', fontWeight: 700 }}>The earlier you add them, the more options you get!</span>
            </p>
            <div>
                {filteredCategories.map((c: Category) => (
                    <div key={c.categoryId} id={`category-${c.categoryId}`}>
                        <img style={{ aspectRatio: '1/1', width: '100%' }} src={transformUrl(`${URL_IMAGES}${c.image}`)} alt={c.name} />
                        {generateLink(c)}
                    </div>
                ))}
            </div>
        </div>
    );
};
